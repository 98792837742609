import React from 'react';
import { CheckInCircleFill } from '@geist-ui/react-icons';
import { XCircleFill } from '@geist-ui/react-icons';
import { Text } from '@geist-ui/react';

const Message = ({ message }) => (
	<section
		style={{
			display: 'flex',
			justifyContent: 'center',
			textAlign: 'center',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: '2%',
		}}
	>
		{message.includes('Upgraded') ? (
			<CheckInCircleFill size='200' color='#2c7a7b' opacity='1' />
		) : (
			<XCircleFill size='200' color='#a70606' opacity='1' />
		)}
		<Text h3 style={{ marginTop: '40px' }}>
			{message}
		</Text>
	</section>
);

export default Message;
