import React, { useEffect, useState } from 'react';
import {
	Button,
	Table,
	Badge,
	Spacer,
	Spinner,
	useModal,
	Modal,
	Input,
	Checkbox,
	Text,
	useToasts,
	Select,
} from '@geist-ui/react';
import MainLayout from '../layouts/MainLayout/MainLayout';
import { addAgent, fetchListData, deleteAgent } from '../constants/requests';

const Agents = () => {
	const { visible, setVisible, bindings } = useModal();
	const [, setToast] = useToasts();
	const [fetchLoader, setFetchLoader] = useState(true);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [campaign, setCampaign] = useState(-1);
	const [campaignList, setCampaignList] = useState([]);

	const operation = (actions, rowData) => {
		return (
			<Button
				type="secondary"
				ghost
				auto
				size="mini"
				onClick={() => deleteAgentHandler(rowData.rowValue.id, actions)}
			>
				Remove
			</Button>
		);
	};

	const deleteAgentHandler = (id, actions) => {
		deleteAgent(id).then((responseData) => {
			if (responseData.status === 204) {
				setToast({
					text: "We've deleted agent successfully",
					type: 'success',
				});
				actions.remove();
			} else {
				setToast({
					text: "We've encountered an error!",
					type: 'error',
				});
			}
		});
	};

	const parseRequiredFields = (resData) => {
		let arr = [];
		resData.map((element) => {
			const obj = {
				id: element.id,
				name: `${element.user.first_name} ${element.user.last_name}`,
				campaign: element.campaign,
				addedBy: element.added_by,
				operation,
			};
			arr.push(obj);
		});
		return arr;
	};

	const getDataList = () => {
		setFetchLoader(true);
		try {
			fetchListData('agents')
				.then(async (responseData) => {
					const newData = parseRequiredFields(responseData);
					setData(newData);
					setFetchLoader(false);
				})
				.catch((err) => console.log(err));
		} catch (error) {
			console.log(error);
		}
	};

	const getCampaignList = () => {
		try {
			fetchListData('campaigns')
				.then(async (responseData) => {
					setCampaignList(responseData);
				})
				.catch((err) => console.log(err));
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getDataList();
		getCampaignList();
	}, []);

	const formSubmitHandler = () => {
		setLoading(true);
		try {
			if(!firstName || !lastName || !email || !username || !password || !campaign || campaign === -1) {
				setToast({
					text: "Please fill all fields !",
					type: 'error',
				});
				setLoading(false);
				return
			}
			addAgent({
				firstName,
				lastName,
				email,
				username,
				password,
				campaign,
			})
				.then(async (responseData) => {
					console.log('🚀 ~ file: index.tsx ~ line 84 ~ .then ~ responseData', responseData);
					if (responseData.status === 201) {
						getDataList();
						setVisible(false);
						setToast({
							text: "We've created agent for you.",
							type: 'success',
						});
						setLoading(false);

						// Reset Form States
						setFirstName('');
						setLastName('');
						setEmail('');
						setUsername('')
						setCampaign(-1);
						setPassword('')
					} else {
						setToast({
							text: "We've encountered an error!",
							type: 'error',
						});
					}
				})
				.catch((err) => {
					console.log(err);
					setToast({
						text: "We've encountered an error!",
						type: 'error',
					});
					setLoading(false)
				});
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<MainLayout>
			<div className="flex flex-col w-full ">
				<div className="body-font w-full p-3">
					<div className="flex flex-col flex-wrap  mx-auto md:items-center md:flex-row justify-between">
						<div>
							<h2>Agents</h2>
						</div>
						<Button type="secondary" size="small" onClick={() => setVisible(true)}>
							Add agent
						</Button>
					</div>
				</div>
				<Modal {...bindings}>
					<Modal.Title>Add agent</Modal.Title>
					<Modal.Content>
						<div className="p-4">
							<div className="flex flex-row justify-between">
								<div>
									<Text size={14}>First name:</Text>
									<Input
										size="medium"
										placeholder="First name"
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</div>
								<div>
									<Text size={14}>Last name:</Text>
									<Input
										size="medium"
										placeholder="Last name"
										onChange={(e) => setLastName(e.target.value)}
									/>
								</div>
							</div>
							<Text size={14}>Username:</Text>
							<Input
								size="medium"
								placeholder="Username"
								width="100%"
								onChange={(e) => setUsername(e.target.value)}
							/>
							<Spacer y={0.5} />
							<Text size={14}>Email:</Text>
							<Input
								size="medium"
								placeholder="Email"
								type="email"
								width="100%"
								onChange={(e) => setEmail(e.target.value)}
							/>
							<Spacer y={0.5} />
							<Text size={14}>Password:</Text>
							<Input
								size="medium"
								placeholder="Password"
								type="password"
								width="100%"
								onChange={(e) => setPassword(e.target.value)}
							/>
							<Spacer y={0.5} />
							<Text size={14}>Campaign:</Text>
							<Select
								placeholder="Choose one"
								onChange={(value) => setCampaign(parseInt(value))}
								width="100%"
							>
								{campaignList.map((item) => (
									<Select.Option value={item.pk.toString()} key={item.pk}>
										{item.name}
									</Select.Option>
								))}
							</Select>
						</div>
					</Modal.Content>
					<Modal.Action passive onClick={() => setVisible(false)}>
						Cancel
					</Modal.Action>
					<Modal.Action onClick={formSubmitHandler} loading={loading}>
						Submit
					</Modal.Action>
				</Modal>
				<div className="container px-4 pr-10 mb-9" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
					{fetchLoader ? (
						<div className="flex justify-center items-center" style={{ minHeight: '50vh' }}>
							<Spinner size="large" />
						</div>
					) : (
						<Table data={data}>
							<Table.Column prop="name" label="name" />
							<Table.Column prop="campaign" label="campaign" />
							<Table.Column prop="addedBy" label="added by" />
							<Table.Column prop="operation" label="action" width={150} />
						</Table>
					)}
				</div>
			</div>
		</MainLayout>
	);
};

export default Agents;
