import { Card, Text, Button, useToasts, Spinner } from "@geist-ui/react";
import React, { useState } from "react";
import { getInvoice } from "../../constants/requests";
import { getParsedDate } from "../../utils/formatDate";
import { getPlanTitle } from "../../utils/helperFunctions";
import { Status } from "./Status";

export const SubscriptionCard = ({ subscription, darkMode }) => {
  const [invoiceDownloading, setInvoiceDownloading] = useState(false);
  const [, setToast] = useToasts();

  const onInvoiceDownload = async () => {
    try {
      setInvoiceDownloading(true);
      const { data: invoiceData } = await getInvoice(subscription.latest_invoice);
      const anchor = document.createElement("a");
      anchor.download = "invoice.pdf";
      anchor.href = invoiceData.invoice_pdf;
      anchor.target = "_blank";
      anchor.click();
    } catch (e) {
      setToast({
        text: "Unable to fetch invoice, please try again later.",
        type: "error",
      });
    } finally {
      setInvoiceDownloading(false);
    }
  };

  return (
    <Card shadow className="relative" key={subscription.id}>
      <Text h3 className="font-light">
        {getPlanTitle(subscription.plan.amount / 100)} Plan
      </Text>
      <Status darkMode={darkMode} status={subscription.status} />
      <Text
        style={{
          color: darkMode ? "#888888" : "#666666",
        }}
        p
        className="font-normal"
      >
        Subscribed since :{" "}
        <span
          className="font-medium"
          style={{
            color: darkMode ? "#999999" : "#333333",
          }}
        >
          {getParsedDate(new Date(subscription.start_date * 1000))}
        </span>
      </Text>
      <Text
        p
        style={{
          color: darkMode ? "#888888" : "#666666",
        }}
      >
        Period:
        {subscription.plan.interval === "month" && (
          <span
            style={{
              color: darkMode ? "#999999" : "#333333",
            }}
            className="font-medium"
          >
            {" "}
            Monthly
          </span>
        )}
      </Text>
      <Text
        p
        style={{
          color: darkMode ? "#888888" : "#666666",
        }}
      >
        Current Cycle:{" "}
        <span
          style={{
            color: darkMode ? "#999999" : "#333333",
            fontWeight: 500,
          }}
        >
          {getParsedDate(new Date(subscription.current_period_start * 1000))} &mdash;{" "}
          {getParsedDate(new Date(subscription.current_period_end * 1000))}
        </span>
      </Text>
      {/* <Text p>
      Collection Method:{' '}
      {subscription.collection_method ===
        'charge_automatically' &&
        'Charge Automatically'}
      {subscription.collection_method ===
        'send_invoice' && `We'll send you invoice.`}
    </Text> */}
      <Text
        p
        style={{
          color: darkMode ? "#888888" : "#666666",
        }}
      >
        Next Invoice on{" "}
        <span
          className="font-medium"
          style={{
            color: darkMode ? "#999999" : "#333333",
          }}
        >
          {getParsedDate(new Date(subscription.pending_invoice_item_interval * 1000))}
        </span>{" "}
        for{" "}
        <span
          className="font-medium"
          style={{
            color: darkMode ? "#999999" : "#333333",
          }}
        >
          {subscription.plan.amount / 100} {subscription.plan.currency.toUpperCase()}
        </span>
      </Text>

      <div>
        {subscription.items.data.map((subscription) => (
          <div key={subscription.id}>
            {/* <Text p>
            {subscription.plan.amount / 100 +
              '.00 '}
            {subscription.plan.currency.toUpperCase()}
            {'/' + subscription.plan.interval}
          </Text> */}
            {/* <Text p>
            Interval Count:{' '}
            {subscription.plan.interval_count}
          </Text> */}
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-5 flex-wrap">
        <Button type="secondary" onClick={onInvoiceDownload}>
          {invoiceDownloading ? <Spinner size="mini" /> : "Download Latest Invoice"}
        </Button>
        <Button className="mt-2 md:mt-0 w-full" type="error-light">
          Cancel Subscription
        </Button>
      </div>
    </Card>
  );
};
