import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { ModalProvider } from './context/ModalContext';
import { ThemeProvider } from './context/ThemeContext';
import './styles/tailwind.css';

const Home = () => {
	return (
		<AuthProvider>
			<ThemeProvider>
				<ModalProvider>
					<App />
				</ModalProvider>
			</ThemeProvider>
		</AuthProvider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<Home />
	</React.StrictMode>,
	document.getElementById('root')
);
