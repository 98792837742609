import { Spinner } from '@geist-ui/react';
import React, { useEffect, useState } from 'react';
import { getSubscriptions } from '../constants/requests';
import MainLayout from '../layouts/MainLayout/MainLayout';
import { useThemeContext } from '../context/ThemeContext';
import { SubscriptionCard } from '../components/Subscription/SubscriptionCard';
import Upgrade from './Upgrade';
import { mainLayoutTypes } from '../constants/static';

export const Subscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const { themeType } = useThemeContext();
  const darkMode = themeType === 'dark';

  const getSubscriptionsLocal = async () => {
    try {
      setLoading(true);
      // @param customerId
      const { data: subscriptionData } = await getSubscriptions('cus_K35KQ30jQxmj6o');
      setSubscriptions(subscriptionData.data);
      console.log(subscriptionData.data);
      // const { data } = await getPaymentMethod(subscriptionData.data.default_payment_method);
      // console.log(data);
      const parsedSubscription = {
        created: '', // Date
        collection_method: '', // enum => charge_automatically, or send_invoice
        start_date: '',
        current_period_start: '', // Date
        current_period_end: '', // Date
        pending_invoice_item_interval: '', // Next Invoice (Date)
        plan: {
          amount: '', // Amount / 100 => actual amount in rs
          currency: '', // string
          interval: '',
        },
      };
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscriptionsLocal();
  }, []);

  return (
    <MainLayout type={mainLayoutTypes.UPGRADE_PAGE}>
      {loading ? (
        <div className="flex justify-center items-center w-full" style={{ minHeight: '50vh' }}>
          <Spinner size="large" />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center w-full" style={{ minHeight: '50vh' }}>
          Unable to fetch your subscription please try again later.
        </div>
      ) : !subscriptions.length ? (
        <>
          <Upgrade />
          {/* <div className="flex justify-center items-center w-full" style={{ minHeight: "50vh" }}>
          You have made no subscriptions yet.
        </div> */}
        </>
      ) : (
        <div className="flex flex-col w-full pt-5">
          {subscriptions.map((subscription) => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              darkMode={darkMode}
            />
          ))}
        </div>
      )}
    </MainLayout>
  );
};
