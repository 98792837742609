import { CssBaseline, GeistProvider } from '@geist-ui/core';
import React, { useState } from 'react';
import { JssProvider } from 'react-jss';
import { useAuthContext } from './context/AuthContext';
import { useThemeContext } from './context/ThemeContext';
import './App.css';

import Routes from './routes';
import Footer from './components/Footer/Footer';

const App = () => {
  const { updateUserInfo } = useAuthContext();
  const { themeType } = useThemeContext();
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const refreshToken = localStorage.getItem('refreshToken')

  //   fetch(uri, {
  //     method: 'POST',
  //     // credentials: 'include',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({
  //       query: queryParams,
  //     }),
  //   }).then(async x => {
  //     const res = await x.json()
  //     setAccessToken(res?.data?.refreshAccessToken?.accessToken)
  //     updateUserInfo(res?.data?.refreshAccessToken?.user)
  //     setLoading(false)
  //   })
  // }, [])

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <>
      <JssProvider id={{ minify: true }}>
        <GeistProvider theme={{ type: themeType }}>
          <CssBaseline />
          <Routes />
        </GeistProvider>
      </JssProvider>
    </>
  );
};

export default App;
