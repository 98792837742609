import React, { useState, useEffect, Suspense } from 'react';
import { tiers } from '../../constants/static.js';
import { Link } from 'react-router-dom';
import { Button, Text } from '@geist-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import Message from './Message.jsx';
import { getProducts, getSession } from '../../constants/requests';
import './upgrade.css';
import ProductPlan from './ProductPlan.jsx';
import { useAuthContext } from '../../context/AuthContext.jsx';
const stripePromise = loadStripe(
  'pk_test_51ILuZJCmeKvWpmHz4AFWxM8wWWsTCdX3oFE8ykhSlrP78LhAon73rrwEiMFEC9XOEWJE3Z9EsrxyRe26WBeQl19p00z3cPNUhU'
);

const Plans = () => {
  const { user } = useAuthContext();

  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();

  const handleClick = async (event, index) => {
    console.log(window.location.href);
    const userDetails = JSON.parse(user);
    setLoading(true);
    setSelectedPlan(index);
    const stripe = await stripePromise;
    const dataString = `success_url=${window.location.href}?success=true&cancel_url=${window.location.href}?cancel=true&payment_method_types[0]=card&line_items[0][price]=${prices[index]}&line_items[0][quantity]=1&mode=subscription&customer_email=${userDetails.email}`;
    const session = await getSession({ dataString });

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session?.id,
    });

    setLoading(false);

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();

    const { data } = response;
    const myPrices = data.filter((d) => d.product === 'prod_JwSh5MVYB6BecL');

    let arr = [];

    myPrices.map((price) => {
      arr.push(price.id);
    });

    const products = [];
    const prices = [...data].reverse();
    tiers.forEach((tier, index) => {
      tier.price = prices[index].unit_amount / 100;
      products[index] = tier;
    });

    setProducts(products);
    setPrices(arr.reverse());
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setMessage('Upgraded! You will receive an email confirmation.');
    }

    if (query.get('cancel')) {
      setMessage("Order canceled -- continue to plans list and checkout when you're ready.");
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      {message ? (
        <div className="stripe_message">
          <div className="stripe_message__back-btn">
            <Link to="/dashboard">
              <Button auto type="secondary">
                Back
              </Button>
            </Link>
          </div>
          <Message message={message} />
        </div>
      ) : (
        <>
          <Text h2 style={{ textAlign: 'center', marginBottom: '40px' }}>
            Choose a Plan
          </Text>
          <div className="pricing-container">
            <Suspense fallback={<div></div>}>
              {products.map((tier, index) => (
                <ProductPlan
                  key={index}
                  tier={tier}
                  handleClick={handleClick}
                  index={index}
                  loading={selectedPlan === index}
                  disabled={loading}
                  lastProduct={products.length - 1 === index}
                />
              ))}
            </Suspense>
          </div>
        </>
      )}
    </div>
  );
};

export default Plans;
