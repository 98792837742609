import Axios from 'axios';

const createTicket = async (name, email, phone, subject, description, uploadFileToken) => {
  const res = await fetch('https://dynopii.zendesk.com/api/v2/requests.json', {
    body: JSON.stringify({
      request: {
        requester: { name, email },
        subject,
        comment: uploadFileToken
          ? {
              body: `${description}\n\n ${phone && 'Phone No.: ' + phone}`,
              uploads: [uploadFileToken],
            }
          : { body: `${description}\n\n ${phone && 'Phone No.: ' + phone}` },
      },
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  const response = await res.json();
  return { status: res.status, data: response };
};

const uploadFile = async (file, fileName, token) => {
  const res = await Axios.post(
    `https://dynopii.zendesk.com/api/v2/uploads?filename=${fileName}${
      token ? `&token=${token}` : ``
    }`,
    file,
    {
      headers: {
        'Content-Type': 'application/binary',
      },
    }
  );
  return res['data']['upload']['token'];
};

const ticketService = {
  createTicket,
  uploadFile,
};

export default ticketService;
