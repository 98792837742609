import { Text } from "@geist-ui/react";
import React from "react";

export const Status = ({ status, darkMode }) => {
  let statusText = status;
  if (status === "incomplete_expired") statusText = "Incomplete Expired";
  if (status === "past_due") statusText = "Past Due";
  return (
    <Text
      p
      style={{
        color:
          status === "past_due"
            ? "#f39c12"
            : status === "unpaid"
            ? "#d35400"
            : status === "canceled"
            ? "#c0392b"
            : status === "incomplete"
            ? "#f1c40f"
            : status === "incomplete_expired"
            ? "#e67e22"
            : status === "trialing"
            ? "#c0392b"
            : "#6FBF86",
      }}
      className={`absolute top-0 right-4 capitalize px-4 py-1 text-white rounded-full font-medium ${
        darkMode
          ? "bg-gray-900"
          : status === "past_due"
          ? "bg-red-100"
          : status === "unpaid"
          ? "bg-red-100"
          : status === "canceled"
          ? "bg-red-200"
          : status === "incomplete"
          ? "bg-yellow-100"
          : status === "incomplete_expired"
          ? "bg-yellow-300"
          : status === "trialing"
          ? "bg-red-100"
          : "bg-green-100"
      }`}
    >
      {statusText}
    </Text>
  );
};
