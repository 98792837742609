import React from 'react';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar';
import SupportForm from '../components/SupportForm';
import { useThemeContext } from '../context/ThemeContext';

const SupportTicket = () => {
  const { switchThemesHandler } = useThemeContext();
  return (
    <>
      <Navbar toggleDarkMode={switchThemesHandler} isLoggedIn={true} />
      <SupportForm />
      <Footer />
    </>
  );
};

export default SupportTicket;
