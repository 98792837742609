import { Book, LogOut, Settings, Users, Volume2, User, CreditCard } from '@geist-ui/react-icons';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useThemeContext } from '../context/ThemeContext';
import './style.css';

const Sidebar = () => {
  const [route, setRoute] = React.useState('');
  const { themeType } = useThemeContext();
  const { removeUserInfo } = useAuthContext();
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    removeUserInfo();
    history.push('/login');
  };

  React.useLayoutEffect(() => {
    setRoute(window.location.href.split('/').pop());
  }, []);

  return (
    <div
      id="sidebar"
      className={`min-w-min sm:w-64 menu_panel flex flex-col justify-between pb-5 ${
        themeType === 'light' ? 'border-r' : 'dark_theme_line'
      }`}
      style={{ maxHeight: 'calc(100vh - 60px)' }}
    >
      <div>
        <Link
          className={`flex mx-2 px-4 py-2 mt-2 text-sm font-semibold ${
            themeType === 'light' ? 'text-gray-900' : 'text-white'
          } rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline ${
            route === 'dashboard' ? 'bg-gray-200 text-gray-900' : ''
          }`}
          to="/admin/dashboard"
        >
          <Book size={20} className="mr-1" />
          <span className="menu_title">Dashboard</span>
        </Link>
        <Link
          className={`flex mx-2  px-4 py-2 mt-2 text-sm font-semibold ${
            themeType === 'light' ? 'text-gray-900' : 'text-white'
          } rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline ${
            route === 'campaigns' ? 'bg-gray-200 text-gray-900' : ''
          }`}
          to="/admin/campaigns"
        >
          <Volume2 size={20} className="mr-1" />
          <span className="menu_title">Campaigns</span>
        </Link>
        <Link
          className={`flex mx-2  px-4 py-2 mt-2 text-sm font-semibold ${
            themeType === 'light' ? 'text-gray-900' : 'text-white'
          } rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline ${
            route === 'agents' ? 'bg-gray-200 text-gray-900' : ''
          }`}
          to="/admin/agents"
        >
          <Users size={20} className="mr-1" />
          <span className="menu_title">Agents</span>
        </Link>
        <Link
          className={`flex mx-2  px-4 py-2 mt-2 text-sm font-semibold ${
            themeType === 'light' ? 'text-gray-900' : 'text-white'
          } rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline ${
            route === 'membership' ? 'bg-gray-200 text-gray-900' : ''
          }`}
          to="/admin/subscription"
        >
          <CreditCard size={20} className="mr-1" />
          <span className="menu_title">Subscription</span>
        </Link>
      </div>
      <div className="pb-3">
        {/* <Link
					className={`flex mx-2  px-4 py-2 mt-2 text-sm font-semibold ${
						themeType === 'light' ? 'text-gray-900' : 'text-white'
					} rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline ${
						route === 'settings' ? 'bg-gray-200 text-gray-900' : ''
					}`}
					to="/admin/settings"
				>
					<Settings size={20} className="mr-1" />
					<span className="menu_title">Settings</span>
				</Link> */}
        <Link
          className={`flex mx-2  px-4 py-2 mt-2 text-sm font-semibold ${
            themeType === 'light' ? 'text-gray-900' : 'text-white'
          } rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
          onClick={handleLogout}
          to="#"
        >
          <LogOut size={20} className="mr-1" />
          <span className="menu_title">Logout</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
