import React, { createContext, useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { setAccessToken } from '../config/accessToken';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(localStorage.getItem('user'));
	const [userType, setUserType] = useState(localStorage.getItem('userType'));

	const setUserInfo = (user, accessToken, refreshToken) => {
		localStorage.setItem('refreshToken', refreshToken);
		setAccessToken(accessToken);
		localStorage.setItem('accessToken', accessToken);
		setUser(user);
		localStorage.setItem('user', JSON.stringify(user));
		localStorage.setItem('userType', user.profile.type);
		setUserType(user.profile.type);
		localStorage.setItem('theme', 'light');
	};

	const updateUserInfo = (user) => setUser((x) => ({ ...x, ...user }));

	const removeUserInfo = () => {
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('user');
		localStorage.removeItem('userType');

		setAccessToken('');
		setUser('');
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				userType,
				setUserInfo,
				updateUserInfo,
				removeUserInfo,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;
