import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Badge,
  Spacer,
  Spinner,
  useModal,
  Modal,
  Input,
  Checkbox,
  Text,
  useToasts,
} from '@geist-ui/react';
import MainLayout from '../layouts/MainLayout/MainLayout';
import { addCampaign, fetchListData, deleteCampaign } from '../constants/requests';

const Campaigns = () => {
  const { visible, setVisible, bindings } = useModal();
  const [, setToast] = useToasts();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [data, setData] = useState([]);
  const [voiceset, setVoiceset] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [campaignDesc, setCampaignDesc] = useState('');

  const operation = (actions, rowData) => {
    return (
      <Button
        type="secondary"
        ghost
        auto
        size="mini"
        onClick={() => deleteCampaignHandler(rowData.rowValue.id, actions)}
      >
        Remove
      </Button>
    );
  };

  const deleteCampaignHandler = (id, actions) => {
    deleteCampaign(id).then((responseData) => {
      if (responseData.status === 204) {
        setToast({
          text: "We've deleted campaign successfully",
          type: 'success',
        });
        actions.remove();
      } else {
        setToast({
          text: "We've encountered an error!",
          type: 'error',
        });
      }
    });
  };

  const parseRequiredFields = (resData) => {
    let arr = [];
    resData.map((element) => {
      const obj = {
        id: element.pk,
        name: element.name,
        description: element.description,
        voices: [
          ...element.voices.map((element) => (
            <>
              <Badge size="mini">{element.name}</Badge> <Spacer x={0.2} />
            </>
          )),
        ],
        operation,
      };
      arr.push(obj);
    });
    return arr;
  };

  const getDataList = () => {
    setFetchLoader(true);
    try {
      fetchListData('campaigns')
        .then(async (responseData) => {
          const newData = parseRequiredFields(responseData);
          setData(newData);
          setFetchLoader(false);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const getVoicesetList = () => {
    try {
      fetchListData('voiceset')
        .then(async (responseData) => {
          let voicesetCheckBoxes = [];
          responseData.map((item) => voicesetCheckBoxes.push(false));
          setCheckedItems(voicesetCheckBoxes);
          setVoiceset(responseData);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const formSubmitHandler = () => {
    setLoading(true);
    const indices = checkedItems.reduce((out, bool, index) => (bool ? out.concat(index) : out), []);

    try {
      if (!campaignName || !campaignDesc || !indices) {
        setToast({
          text: 'Please fill all fields !',
          type: 'error',
        });
        setLoading(false);
        return;
      }
      addCampaign({
        name: campaignName,
        description: campaignDesc,
        voices: indices,
      })
        .then(async (responseData) => {
          if (responseData.status === 201) {
            getDataList();
            setVisible(false);

            setLoading(false);

            // Reset Form States
            setCampaignName('');
            setCampaignDesc('');
            setCheckedItems([]);

            setToast({
              text: "We've created campaign for you.",
              type: 'success',
            });
          } else {
            setToast({
              text: "We've encountered an error!",
              type: 'error',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setToast({
            text: "We've encountered an error!",
            type: 'error',
          });
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataList();
    getVoicesetList();
  }, []);

  return (
    <MainLayout>
      <div className="flex flex-col w-full ">
        <div className="body-font w-full p-3">
          <div className="flex flex-col flex-wrap  mx-auto md:items-center md:flex-row justify-between">
            <div>
              <h2>Campaigns</h2>
            </div>
            <Button type="secondary" size="small" onClick={() => setVisible(true)}>
              Add campaign
            </Button>
          </div>
        </div>
        <Modal {...bindings}>
          <Modal.Title>Add campaign</Modal.Title>
          <Modal.Content>
            <div className="p-4">
              <Text size={14}>Campaign name:</Text>
              <Input
                size="medium"
                placeholder="Campaign name"
                width="100%"
                onChange={(e) => setCampaignName(e.target.value)}
              />
              <Spacer y={0.5} />
              <Text size={14}>Description:</Text>
              <Input
                size="medium"
                placeholder="Description"
                width="100%"
                onChange={(e) => setCampaignDesc(e.target.value)}
              />
              <Spacer y={0.5} />
              <Text size={14}>Voices:</Text>
              <div className="p-1">
                {voiceset.map((item) => (
                  <>
                    <Checkbox
                      size="medium"
                      key={item.id}
                      checked={checkedItems[item.id]}
                      onChange={(e) => {
                        const temp = [...checkedItems];
                        temp[item.id] = e.target.checked;
                        setCheckedItems(temp);
                      }}
                    >
                      {item.name}
                    </Checkbox>
                    <Spacer y={0.2} />
                  </>
                ))}
              </div>
            </div>
          </Modal.Content>
          <Modal.Action passive onClick={() => setVisible(false)}>
            Cancel
          </Modal.Action>
          <Modal.Action onClick={formSubmitHandler} loading={loading}>
            Submit
          </Modal.Action>
        </Modal>
        <div className="container px-4 pr-10 mb-9" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {fetchLoader ? (
            <div className="flex justify-center items-center" style={{ minHeight: '50vh' }}>
              <Spinner size="large" />
            </div>
          ) : (
            <Table id="campaign-table" data={data}>
              <Table.Column prop="name" label="name" />
              <Table.Column prop="description" label="description" />
              <Table.Column prop="voices" label="voices" />
              <Table.Column prop="operation" label="action" width={150} />
            </Table>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default Campaigns;
