import { Button, Card, Grid, Input, Page, Spacer, Text, Image } from '@geist-ui/react';
import { Lock, User, Mail } from '@geist-ui/react-icons';
import React, { useState } from 'react';
import { loginUser } from '../../constants/requests';
import { useAuthContext } from '../../context/AuthContext';
import makeStyles from '../../utils/makeStyles';
import _ from 'lodash';
import Navbar from '../../components/Navbar';
import { useThemeContext } from '../../context/ThemeContext';
import VoiceIcon from '../../images/voice.svg';
import LocationIcon from '../../images/location.svg';
import { useToasts } from '@geist-ui/react';
import Footer from '../../components/Footer/Footer';

const useStyles = makeStyles((ui) => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputField: {
    '& .input-wrapper': {
      minWidth: '300px !important',
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [, setToast] = useToasts();
  const { switchThemesHandler } = useThemeContext();
  const { setUserInfo } = useAuthContext();
  const [username, setUsername] = useState('dhwaniagent');
  const [email, setEmail] = useState('dhwaniagent@dynopii.com');
  const [password, setPassword] = useState('DemoPass098');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (data) => {
    const res = await loginUser(data);
    if (!_.isEmpty(res) && !_.has(res, 'non_field_errors')) {
      setIsLoading(false);
      setUserInfo(res.user, res.access_token, res.refresh_token);
    } else {
      setToast({
        text: 'Invalid login credentials',
        type: 'error',
      });
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    let data = {
      username,
      email,
      password,
    };
    handleLogin(data);
  };

  return (
    <>
      <Navbar toggleDarkMode={switchThemesHandler} isLoggedIn={false} />
      <Page dotBackdrop={false} size="mini" className="cmainclass">
        <Grid.Container gap={12} justify="center">
          <Card shadow style={{ minHeight: '75vh', minWidth: '40vw' }}>
            <div style={{ position: 'absolute', top: 0, right: '7vw' }}>
              <Image src={LocationIcon} width={120} height={120} />
            </div>
            <div className={classes.container}>
              <Page.Header style={{ textAlign: 'center', marginTop: '2vh' }}>
                <Text h2>Login</Text>
              </Page.Header>
              <div style={{ marginTop: '7vh' }}>
                <Input
                  icon={<User />}
                  placeholder="Username"
                  size="large"
                  width={100}
                  data-testid="username"
                  value={username}
                  className={classes.inputField}
                  onChange={({ target }) => setUsername(target.value)}
                />
                <Spacer y={1} />
                <Input
                  icon={<Mail />}
                  placeholder="Email"
                  size="large"
                  width={100}
                  data-testid="email"
                  value={email}
                  className={classes.inputField}
                  onChange={({ target }) => setEmail(target.value)}
                />
                <Spacer y={1} />
                <Input.Password
                  icon={<Lock />}
                  placeholder="Password"
                  size="large"
                  width={100}
                  data-testid="password"
                  value={password}
                  className={classes.inputField}
                  onChange={({ target }) => setPassword(target.value)}
                />
                <Spacer y={1} />
                <Button id="login__button" loading={isLoading} onClick={onSubmit}>
                  Login
                </Button>
              </div>
            </div>
            <div style={{ position: 'absolute' }}>
              <Image src={VoiceIcon} width={150} height={150} />
            </div>
          </Card>
        </Grid.Container>
      </Page>
      <Footer />
    </>
  );
};

export default Login;
