import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Plans from '../components/Upgrade/Plans';

const stripePromise = loadStripe(
  'pk_test_51ILuZJCmeKvWpmHz4AFWxM8wWWsTCdX3oFE8ykhSlrP78LhAon73rrwEiMFEC9XOEWJE3Z9EsrxyRe26WBeQl19p00z3cPNUhU'
);

const Upgrade = () => {
  return (
    <>
      <div className="payment-container">
        <Elements stripe={stripePromise}>
          <Plans />
        </Elements>
      </div>
    </>
  );
};

export default Upgrade;
