import { Card, Grid, Image, Note, Page, Select, Spinner } from '@geist-ui/react';
import React from 'react';
import ReactJoyride, { STATUS } from 'react-joyride';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar';
// import Speech from '../components/Speech';
import Speech from '../components/SpeechNew';
import { fetchListData } from '../constants/requests';
import { useThemeContext } from '../context/ThemeContext';
import AvatarImage from '../images/avatar.svg';
const steps = [
  {
    content: <p>Welcome to Dhwani</p>,
    placement: 'center',
    target: 'body',
  },
  {
    content: <p>Choose any voice you want</p>,
    floaterProps: {
      disableAnimation: true,
    },
    spotlightPadding: 20,
    target: '.voice-select',
  },
  {
    content: 'Click on the microphone and start speaking',
    placement: 'bottom',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '.speech-container',
    // title: 'Our projects',
  },
  {
    placement: 'bottom',
    target: '.profile-btn',
    content: 'You can click on profile button access audio settings.',
    textAlign: 'center',
    spotlightClicks: true,
  },
  {
    content: (
      <div>
        <p>Thank you for opting Dhwani!</p>
      </div>
    ),
    placement: 'center',
    target: 'body',
  },
];

const Dashboard = () => {
  const { switchThemesHandler } = useThemeContext();
  const [userString, setUserString] = React.useState('');
  const [tutorial2Passed, setTutorial2Passed] = React.useState(
    window.localStorage.getItem('tutorial2Passed') ?? false
  );
  const [voice, setVoice] = React.useState('');
  const handler = (val) => setVoice(val);

  React.useEffect(() => {
    fetchListData('agents').then((res) => setUserString(`${res.added_by}_${res.username}`));
  }, []);

  return (
    <>
      <Navbar toggleDarkMode={switchThemesHandler} isLoggedIn={true} />
      <Page dotBackdrop={false} size="mini" className="cmainclass-dashboard">
        <Grid.Container gap={12} justify="center">
          <ReactJoyride
            callback={({ status }) => {
              if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                window.localStorage.setItem('tutorial2Passed', 'true');
                setTutorial2Passed(true);
              }
            }}
            steps={steps}
            continuous={true}
            run={!tutorial2Passed}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
          />
          <Card shadow style={{ maxHeight: '80vh' }}>
            <div
              style={{
                textAlign: 'center',
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <Note type="secondary" label={false} style={{ maxWidth: '' }}>
                  Click on the microphone icon and begin speaking.
                </Note>
              </div>
              <div>
                <Image src={AvatarImage} height={120} width={120} />
              </div>
              <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                <Select placeholder="Select voice" onChange={handler} className="voice-select">
                  <Select.Option value="male1">Male 1</Select.Option>
                  <Select.Option value="male2">Male 2</Select.Option>
                  <Select.Option value="female1">Female 1</Select.Option>
                  <Select.Option value="female2">Female 2</Select.Option>
                </Select>
              </div>
              {userString !== '' ? (
                <Speech userString={userString} voice={voice} className="speech-container" />
              ) : (
                <Spinner />
              )}
            </div>
          </Card>
        </Grid.Container>
      </Page>
      <Footer />
    </>
  );
};

export default Dashboard;
