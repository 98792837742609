import React, { createContext, useContext } from "react";

export const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [themeType, setThemeType] = React.useState(() => {
    const theme = localStorage.getItem("theme");
    if (!theme) localStorage.setItem("theme", "light");
    return localStorage.getItem("theme");
  });
  const switchThemesHandler = () => {
    const root = window.document.documentElement;
    const isDark = themeType === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(themeType);
    setThemeType((last) => (last === "dark" ? "light" : "dark"));
    const theme = localStorage.getItem("theme");
    localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
  };
  return (
    <ThemeContext.Provider
      value={{
        themeType,
        switchThemesHandler,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContext;
