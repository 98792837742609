export const BASE_URL = 'https://dhwani-prod.cloud.dpi.one';

export const url = {
	AUTH_LOGIN: BASE_URL + '/auth/login/',
	REGISTER_USER: '/auth/register/',
	LOGIN_USER: '/auth/login/',
	REFRESH_TOKEN: '/auth/token/refresh/',
	VERIFY_TOKEN: '/auth/token/verify/',
	GET_USER: '/auth/user/',
	ORGANIZATION: '/organizations/',
	CAMPAIGN: '/campaigns/',
	AGENTS: '/agents/',
	USER_PROFILES: '/users/',
};
