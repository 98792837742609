import React, { createContext, useContext } from 'react';

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
	const [modalState, setModalState] = React.useState(false);
	const toggleModalState = () => {
		setModalState((prev) => !prev);
	};
	return (
		<ModalContext.Provider
			value={{
				modalState,
				toggleModalState,
				setModalState,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => useContext(ModalContext);

export default ModalContext;
