import { Button, Card, Text } from '@geist-ui/react';
import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';

const ProductPlan = ({ tier, handleClick, loading, lastProduct, index, disabled }) => {
  const { themeType } = useThemeContext();

  return (
    <div
      className={`price-box margin-right-0 ${tier.title === 'Pro' ? 'price-box--pro' : ''} ${
        lastProduct ? 'margin-fix' : ''
      }`}
      style={{
        boxShadow:
          themeType === 'dark' ? '0 5px 20px rgba(255,255,255,0.1)' : '0 5px 20px rgba(0,0,0,0.07)',
        border:
          themeType === 'dark' && tier.title === 'Pro'
            ? 'none'
            : themeType === 'dark'
            ? '0.5px solid rgb(, 51, 51)'
            : 'none',
      }}
    >
      <Text h3>{tier.title}</Text>
      <Text p>{tier.subheader}</Text>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'baseline',
        }}
      >
        <Text h2 style={{ marginRight: 10 }}>
          ₹{tier.price}
        </Text>
        <Text p> /mo</Text>
      </div>

      <div>
        {tier.description.map((line) => (
          <Text p key={line}>
            {line}
          </Text>
        ))}
      </div>

      <div style={{ marginTop: 'auto' }}>
        <Button
          style={{
            width: '100%',
            backgroundColor:
              themeType === 'dark' && tier.title === 'Pro'
                ? '#222222'
                : themeType === 'dark'
                ? '#000000'
                : '#ffffff',
            color:
              themeType === 'dark' && tier.title === 'Pro'
                ? '#f4f4f4'
                : themeType === 'dark'
                ? '#eaeaea'
                : '#222222',
          }}
          loading={loading}
          disabled={disabled}
          onClick={(event) => handleClick(event, index)}
        >
          {tier.buttonText}
        </Button>
      </div>
    </div>
  );
};

export default ProductPlan;
