import { Button, Card, Grid, Input, Page, Spacer, Text } from '@geist-ui/react';
import React, { useState } from 'react';
import { Mail, Phone } from '@geist-ui/icons';
import { User, X } from '@geist-ui/react-icons';
import { Select, Textarea } from '@geist-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import './styles.css';
import ticketService from './ticket-service';

const SupportForm = () => {
  const [category, setCategory] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [desc, setDesc] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const handleTicketSubmit = async (e) => {
    e.preventDefault();
    const subject = getSubjectText();
    if (name && email && desc && subject) {
      setLoading(true);
      const uploadFileToken = await uploadFile();
      const response = await ticketService.createTicket(
        name,
        email,
        phone,
        subject,
        desc,
        uploadFileToken
      );
      let statusText = '';
      if (response.status !== 201) {
        console.log('🚀 ~ file: index.jsx ~ line 38 ~ handleTicketSubmit ~ response', response);
        const { description, error } = response.data;
        toast.error(`[${error}] ${description}`, {
          duration: 3000,
        });
      } else {
        console.log('response.status ', response.status);
        statusText = 'Ticket was created. Look for an email notification.';
        toast.success(statusText, {
          duration: 3000,
        });
        clearForm();
      }
      setLoading(false);
    } else {
      toast.error('Please fill required fields');
    }
  };

  const handler = (val) => setCategory(val);

  const clearForm = () => {
    setSelectedFiles([]);
    setPhone('');
    setCategory(1);
    setDesc('');
    setName('');
    setEmail('');
  };

  const getSubjectText = () => {
    let subject = '';
    switch (category) {
      case '1':
        subject = 'Bug Report';
        break;
      case '2':
        subject = `Usage problem`;
        break;
      case '3':
        subject = 'Feature Request';
        break;
      default:
        subject = '';
    }
    return subject;
  };

  const uploadFile = async () => {
    //Update the formData object
    if (selectedFiles.length > 0) {
      let token = null;
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        if (i === 0) token = await ticketService.uploadFile(selectedFile, selectedFile.name, token);
        else await ticketService.uploadFile(selectedFile, selectedFile.name, token);
      }
      return token;
    }
    return null;
  };

  const onFileChange = (e) => {
    if (e.target.files) {
      setSelectedFiles([...selectedFiles, e.target.files[0]]);
    }
  };
  const handleFileDelete = (index) => {
    setSelectedFiles(selectedFiles.filter((selectedFile, i) => i !== index));
  };
  return (
    <>
      <Toaster />
      <Page dotBackdrop={false} size="mini" className="cmainclass-dashboard">
        <Grid.Container gap={12} justify="center">
          <Card>
            <form
              style={{
                textAlign: 'center',
                alignContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
              onSubmit={handleTicketSubmit}
            >
              <Text h4>Raise support ticket</Text>
              <Spacer h={0.5} />

              <div
                style={{
                  width: '80%',
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Input
                  icon={<User />}
                  placeholder="Full Name"
                  width="70%"
                  onChange={(e) => setName(e.target.value)}
                  type={'text'}
                  required
                />
                <Spacer h={0.5} />
                <Input
                  icon={<Mail />}
                  placeholder="Email"
                  width="70%"
                  onChange={(e) => setEmail(e.target.value)}
                  type={'email'}
                  required
                />
                <Spacer h={0.5} />
                <Input
                  icon={<Phone />}
                  placeholder="Phone"
                  width="70%"
                  onChange={(e) => setPhone(e.target.value)}
                  type={'tel'}
                />
                <Spacer h={0.5} />
                <Select
                  placeholder="Select category"
                  onChange={handler}
                  type="default"
                  width={'50%'}
                  className={'selectbox'}
                >
                  <Select.Option value="1">Bug Report</Select.Option>
                  <Select.Option value="2">Usage problem</Select.Option>
                  <Select.Option value="3">Feature Request</Select.Option>
                </Select>
                <Spacer h={0.5} />
                <Textarea
                  placeholder="Please describe your problem"
                  width={'50%'}
                  height={'100px'}
                  onChange={(e) => setDesc(e.target.value)}
                  required
                />
                <Spacer h={0.5} />
                <div className="file-upload-container">
                  <label className="file-uploader">
                    <svg
                      className="file-uploader-icon"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="file-uploader-text">
                      <span className="no-file-selected">Select files</span>
                    </span>
                    <input type="file" className="file-uploader-input" onChange={onFileChange} />
                  </label>
                  <Spacer h={0.5} />
                  {selectedFiles.length > 0 && (
                    <div className="files-container">
                      {selectedFiles.map((selectedFile, i) => (
                        <div className="selected-file" key={i}>
                          <span>{selectedFile.name}</span>
                          <i>
                            <X
                              size={14}
                              onClick={() => {
                                handleFileDelete(i);
                              }}
                            />
                          </i>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <Spacer h={0.5} />
                <Button type="secondary" loading={loading} ghost htmlType="submit">
                  Create Ticket
                </Button>
              </div>
            </form>
          </Card>
        </Grid.Container>
      </Page>
    </>
  );
};

export default SupportForm;
