import { Button } from '@geist-ui/react';
import React from 'react';
import { Status } from '../components/Dashboard/Status';
import { users } from '../constants/static';
import { useThemeContext } from '../context/ThemeContext';
import MainLayout from '../layouts/MainLayout/MainLayout';

const AdminPanel = () => {
  const { themeType } = useThemeContext();
  const darkMode = themeType === 'dark';
  return (
    <MainLayout>
      <div className="flex flex-col w-full ">
        <div className="body-font w-full p-3">
          <div className="flex flex-col flex-wrap  mx-auto md:items-center md:flex-row justify-between">
            <div>
              <h2>Dashboard</h2>
            </div>
            <Button type="secondary" size="small">
              Create report
            </Button>
          </div>
        </div>
        <div className="container px-4 pr-10">
          <div className="py-8">
            <div>
              <h2 className="text-2xl font-semibold leading-tight">Users</h2>
            </div>
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overscroll-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg">
                <table className="w-full leading-normal table-auto rounded-md overflow-hidden">
                  <thead className="">
                    <tr
                      className={`border-b ${
                        darkMode
                          ? 'bg-gray-900 text-gray-500 border-gray-900'
                          : 'bg-gray-100 text-gray-600 border-gray-50'
                      }`}
                    >
                      <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                        User
                      </th>
                      <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                        Role
                      </th>
                      <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                        Created at
                      </th>
                      <th className="px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, i) => (
                      <tr key={i}>
                        <td
                          className={`px-5 py-5 border-b border-gray-200 text-sm ${
                            darkMode ? 'bg-gray-800 border-gray-900' : 'bg-white border-gray-200'
                          }`}
                        >
                          <div className="flex items-center">
                            <div className="flex-shrink-0 w-10 h-10">
                              <img className="w-full h-full rounded-full" src={user.photo} alt="" />
                            </div>
                            <div className="ml-3">
                              <p
                                className={`${
                                  darkMode ? 'text-gray-50' : 'text-gray-900'
                                } whitespace-no-wrap`}
                              >
                                {user.fullName}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td
                          className={`px-5 py-5 border-b border-gray-200 text-sm ${
                            darkMode ? 'bg-gray-800 border-gray-900' : 'bg-white border-gray-200'
                          }`}
                        >
                          <p
                            className={`${
                              darkMode ? 'text-gray-50' : 'text-gray-900'
                            } whitespace-no-wrap`}
                          >
                            {user.role}
                          </p>
                        </td>
                        <td
                          className={`px-5 py-5 border-b text-sm ${
                            darkMode ? 'bg-gray-800 border-gray-900' : 'bg-white border-gray-200'
                          }`}
                        >
                          <p
                            className={`${
                              darkMode ? 'text-gray-50' : 'text-gray-900'
                            } whitespace-no-wrap`}
                          >
                            {user.createdAt}
                          </p>
                        </td>
                        <td
                          className={`px-5 py-5 border-b border-gray-200 text-sm ${
                            darkMode ? 'bg-gray-800 border-gray-900' : 'bg-white border-gray-200'
                          }`}
                        >
                          <Status
                            textClass={user.textClass}
                            bgClass={user.bgClass}
                            status={user.status}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  className={`px-5 py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-sm ${
                    darkMode
                      ? 'bg-gray-700 border-gray-900'
                      : 'bg-white'
                  }`}
                >
                  <span
                    className={`text-xs xs:text-sm ${darkMode ? 'text-gray-300' : 'text-gray-800'}`}
                  >
                    Showing 1 to 4 of 50 Entries
                  </span>
                  <div className="inline-flex mt-2 xs:mt-0">
                    <button
                      className={`text-sm hover:bg-gray-400 font-semibold py-2 px-4 rounded-l ${
                        darkMode ? 'text-gray-50 bg-gray-900' : 'text-gray-800 bg-gray-300'
                      }`}
                    >
                      Prev
                    </button>
                    <button
                      className={`text-sm hover:bg-gray-400 font-semibold py-2 px-4 rounded-r ${
                        darkMode ? 'text-gray-50 bg-gray-900' : 'text-gray-800 bg-gray-300'
                      }`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AdminPanel;
