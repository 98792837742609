export const tiers = [
  {
    title: 'Basic',
    price: 1000,
    description: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    buttonText: 'Purchase',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: 2000,
    description: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    buttonText: 'Purchase',
  },
  {
    title: 'Enterprise',
    price: 3000,
    description: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    buttonText: 'Purchase',
  },
];

export const mainLayoutTypes = {
  UPGRADE_PAGE: 'UPGRADE_PAGE',
};

export const users = [
  {
    photo: `https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80`,
    fullName: 'Vera Carpenter',
    role: 'Admin',
    createdAt: 'Jan 21, 2020',
    status: 'Active',
    textClass: 'text-green-900',
    bgClass: 'bg-green-200',
  },
  {
    photo: `https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80`,
    fullName: 'Blake Bowman',
    role: 'Editor',
    createdAt: 'Jan 01, 2020',
    status: 'Active',
    textClass: 'text-green-900',
    bgClass: 'bg-green-200',
  },
  {
    photo: `https://images.unsplash.com/photo-1540845511934-7721dd7adec3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80`,
    fullName: 'Dana Moore',
    role: 'Editor',
    createdAt: 'Jan 10, 2020',
    status: 'Suspended',
    textClass: 'text-yellow-900',
    bgClass: 'bg-yellow-200',
  },
  {
    photo: `https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&h=160&w=160&q=80`,
    fullName: 'Alonzo Cox',
    role: 'Admin',
    createdAt: 'Jan 18, 2020',
    status: 'Inactive',
    textClass: 'text-red-900',
    bgClass: 'bg-red-200',
  },
];
