import React from 'react';
import logo from '../../images/dynopii.svg';

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font w-full">
      <div className="container px-5 py-8 mx-auto flex items-center justify-center sm:flex-row flex-col">
        <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
          <img src={logo} alt="" srcSet="" />
        </a>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mb-0">
          © 2021 - Dynopii
          {/* —
					<a
						href="https://twitter.com/dynopii"
						className="text-gray-600 ml-1"
						rel="noopener noreferrer"
						target="_blank"
					>
						@dynopii
					</a> */}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
