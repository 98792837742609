import axios from "axios";
import { refreshToken, verifyToken } from "./requests";
import { BASE_URL } from "./urls";

export const dhwani = axios.create({
  baseURL: BASE_URL,
});

dhwani.defaults.headers.post["Content-Type"] = "application/json";

dhwani.interceptors.response.use(
  (res) => res,
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      // if (error?.response?.data?.code === "token_not_valid") {
      verifyToken()
        .then((val) => {})
        .catch((error) => {
          if (error?.response?.data?.code === "token_not_valid") {
            refreshToken()
              .then((val) => {
                localStorage.setItem("accessToken", val.data.access);
                window.location.reload();
              })
              .catch((error) => {
                window.location.replace("/login");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("user");
                localStorage.removeItem("userType");
              });
          }
        });
    }
    return Promise.reject(error);
  }
);
