import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import Login from '../pages/Login/Login';
import Register from '../pages/Register';

const AuthRoute = () => {
  const { user } = useAuthContext();

  useEffect(() => {
    if (user && user !== 'undefined') {
      <Redirect to="/dashboard" />;
    }
  }, []);
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/" component={() => <Redirect to="/login" />} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default AuthRoute;
