import React from 'react';

export const Status = ({ status, textClass, bgClass }) => {
  return (
    <span className={`relative inline-block px-3 py-1 font-semibold leading-tight ${textClass}`}>
      <span aria-hidden className={`absolute inset-0 opacity-50 rounded-full ${bgClass}`}></span>
      <span className="relative">{status}</span>
    </span>
  );
};
