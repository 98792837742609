import Sidebar from '../../components/Sidebar';
import React from 'react';
import Navbar from '../../components/Navbar';
import { useThemeContext } from '../../context/ThemeContext';
import './MainLayout.css';
import Footer from '../../components/Footer/Footer';
import { mainLayoutTypes } from '../../constants/static';

const MainLayout = ({ children, type }) => {
  const { switchThemesHandler } = useThemeContext();
  const upgradePage = type === mainLayoutTypes.UPGRADE_PAGE;
  return (
    <>
      <Navbar toggleDarkMode={switchThemesHandler} isLoggedIn={true} />
      <div id="app" className="h-screen flex">
        <Sidebar />
        <div className="flex-1 flex overflow-hidden flex-col">
          <div className={`px-6 flex-1 overflow-y-scroll ${upgradePage ? '' : 'py-4'}`}>
            <div className={`rounded-lg flex ${upgradePage ? 'flex-1 justify-center' : 'p-8 '}`}>
              {children}
            </div>
          </div>
          <div className="w-full mb-10">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
