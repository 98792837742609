import React, { FC, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { verifyToken } from "../constants/requests";
import { useAuthContext } from "../context/AuthContext";
import AuthRoute from "./AuthRoute";
import MainRoute from "./MainRoute";

const Routes = () => {
  const { user } = useAuthContext();
  // If access token expired /auth/token/verify/
  // get new access token by calling /auth/token/refresh/ then save new AT to local storage
  // Show the Main route

  // If refresh expired redirect to /login (AuthRoute)

  // Axios interceptors
  // https://stackoverflow.com/questions/51646853/automating-access-token-refreshing-via-interceptors-in-axios

  return (
    <BrowserRouter>{user && user !== undefined ? <MainRoute /> : <AuthRoute />}</BrowserRouter>
  );
};

export default Routes;
