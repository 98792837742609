import axios from "axios";
import { dhwani } from ".";
import { httpConfig } from "../utils/localStorageHelper";
import { url } from "./urls";

export const loginUser = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(url.AUTH_LOGIN, requestOptions)
    .then((response) => response.json())
    .then((data) => data)
    .catch((e) => console.log(e));
};

export const addAgent = async (params) => {
  const config = await httpConfig();
  const json = JSON.stringify({
    user: {
      first_name: params.firstName,
      last_name: params.lastName,
      email: params.email,
      username: params.username,
      password: params.password,
    },
    campaign: params.campaign,
  });
  return dhwani
    .post(url.AGENTS, json, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const addCampaign = async (params) => {
  console.log("🚀 ~ file: requests.js ~ line 38 ~ addCampaign ~ params", params);
  const config = await httpConfig();
  const json = JSON.stringify({
    name: params.name,
    description: params.description,
    voices: params.voices,
  });
  return dhwani
    .post(url.CAMPAIGN, json, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const deleteCampaign = async (params) => {
  const config = await httpConfig();
  return dhwani
    .delete(`${url.CAMPAIGN}${params}/`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const deleteAgent = async (params) => {
  const config = await httpConfig();
  return dhwani
    .delete(`${url.AGENTS}${params}/`, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const fetchListData = async (route) => {
  const config = await httpConfig();
  return dhwani
    .get(`/${route}/`, config)
    .then((response) => response.data)
    .catch((err) => console.log(err));
};

export const verifyToken = async () => {
  const config = await httpConfig();
  const accessToken = localStorage.getItem("accessToken");
  return dhwani({
    url: `${url.VERIFY_TOKEN}`,
    headers: config.headers,
    method: "POST",
    data: {
      token: accessToken,
    },
  });
};

export const refreshToken = async () =>
  dhwani({
    url: `${url.REFRESH_TOKEN}`,
    data: {
      refresh: localStorage.getItem("refreshToken"),
    },
    method: "POST",
  });

// Stripe Requests

export const getSession = ({ dataString }) =>
  fetch("https://api.stripe.com/v1/checkout/sessions", {
    method: "POST",
    headers: {
      Authorization:
        "Bearer " +
        "sk_test_51ILuZJCmeKvWpmHz3TlXzWoxcKDhxC1H79dST7HZWq77AihEPFbqGH1prC1AuhQ7s6fsx2vHudP4cSIad9gGMQkR00Yw5Pz0yX",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: dataString,
  })
    .then((response) => response.json())
    .then((json) => {
      console.log("json", json);
      return json;
    })
    .catch((err) => console.log("errrr", err));

export const getProducts = () =>
  fetch("https://api.stripe.com/v1/prices?product=prod_JwSh5MVYB6BecL", {
    method: "GET",
    headers: {
      Authorization:
        "Bearer " +
        "sk_test_51ILuZJCmeKvWpmHz3TlXzWoxcKDhxC1H79dST7HZWq77AihEPFbqGH1prC1AuhQ7s6fsx2vHudP4cSIad9gGMQkR00Yw5Pz0yX",
    },
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    });

export const getSubscriptions = (customerId) => {
  const params = new URLSearchParams();
  params.append("customer", customerId);

  return axios({
    url: "https://api.stripe.com/v1/subscriptions",
    params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        "Bearer " +
        "sk_test_51ILuZJCmeKvWpmHz3TlXzWoxcKDhxC1H79dST7HZWq77AihEPFbqGH1prC1AuhQ7s6fsx2vHudP4cSIad9gGMQkR00Yw5Pz0yX",
    },
  });
};

export const getPaymentMethod = (paymentId) => {
  return axios({
    url: `https://api.stripe.com/v1/payment_methods/${paymentId}`,
    method: "GET",
    headers: {
      Authorization:
        "Bearer " +
        "sk_test_51ILuZJCmeKvWpmHz3TlXzWoxcKDhxC1H79dST7HZWq77AihEPFbqGH1prC1AuhQ7s6fsx2vHudP4cSIad9gGMQkR00Yw5Pz0yX",
    },
  });
};

export const getInvoice = (invoiceId) => {
  return axios({
    url: `https://api.stripe.com/v1/invoices/${invoiceId}`,
    method: "GET",
    headers: {
      Authorization:
        "Bearer " +
        "sk_test_51ILuZJCmeKvWpmHz3TlXzWoxcKDhxC1H79dST7HZWq77AihEPFbqGH1prC1AuhQ7s6fsx2vHudP4cSIad9gGMQkR00Yw5Pz0yX",
    },
  });
};
